@use "@platform/theme/src/default/variables" as v;
@use "@platform/theme/src/default/rupture";

// project-specific
@use "TabLayoutShell";

button.cer-button-primary {
  border-color: var(--primary) !important;
  color: var(--scale-600) !important;
  background-color: var(--primary) !important;
  background-image: none !important;

  &:hover {
    background-color: hsl(var(--primaryHslHue), var(--primaryHslSaturation), var(--primaryHslLightnessDark)) !important;
    text-decoration: none !important;
  }

  &:active {
    background-color: hsl(var(--primaryHslHue), var(--primaryHslSaturation), var(--primaryHslLightnessDarker)) !important;
    text-decoration: none !important;
  }
}

button.cer-button-icon-text-primary > span[role="img"] {
  color: var(--primary) !important;
  background-image: none !important;
}

button.cer-button-secondary {
  border-color: var(--primary) !important;
  color: var(--primary) !important;
  background-color: var(--scale-600) !important;
  background-image: none !important;

  &:hover {
    background-color: hsl(var(--primaryHslHue), var(--primaryHslSaturation), 95%) !important;
    text-decoration: none !important;
  }

  &:active {
    background-color: hsl(var(--primaryHslHue), var(--primaryHslSaturation), 90%) !important;
    text-decoration: none !important;
  }
}

.k-switch-on .k-switch-container {
  background-color: var(--primary);
}

.k-radio-item input[type="radio"]:checked {
  border-color: var(--primary) !important;
  color: var(--primary) !important;
}

.k-radio:checked:focus {
  border-color: var(--primary) !important;
  box-shadow: 0 0 0 2px rgb(17 98 194 / 30%) !important;
}